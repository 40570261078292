/* ################################################################### */
/* FUNCTION (STICKY HEADER) ########################################## */
/* ################################################################### */
function stickyHeader()
{
	if(window.pageYOffset > 0) {
		jQuery('#header').addClass('is-sticky');
		jQuery('#alert_banner_con').addClass('is-sticky');

		jQuery('#body').addClass('alert-margin');
	}
	else {
		jQuery('#alert_banner_con').removeClass('is-sticky');
		jQuery('#body').removeClass('alert-margin');
	}

	window.onscroll = function()
	{
		if(window.pageYOffset > 0) {
			jQuery('#header').addClass('is-sticky');
			jQuery('#alert_banner_con').addClass('is-sticky');
			jQuery('#body').addClass('alert-margin');
		}
		else {
			jQuery('#header').removeClass('is-sticky');
			jQuery('#alert_banner_con').removeClass('is-sticky');
			jQuery('#body').removeClass('alert-margin');
		}
	}
}
