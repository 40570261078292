/* ######################################################################################################################## */
/* FUNCTION (MODAL WINDOW) ################################################################################################ */
/* ######################################################################################################################## */
function modalWindow(speed, ease)
{
	jQuery('.js-modal_trigger').on('click', function(e)
	{
		e.preventDefault();
		e.stopPropagation();

		var jQuery_this = jQuery(this);
			idModal     = jQuery_this.attr('href');

		toggleModalwindow(idModal);
	});

	jQuery(document)
		.on('click', function(e)
		{
			if(jQuery('.js-modal_wrapper.open').length > 0)
			{
				if (jQuery(e.target).closest('.js-modal_content').length === 0)
					closeModalwindows();
			}
		});
	jQuery(document)
		.on('keyup', function(e)
		{
			if(jQuery('.js-modal_wrapper.open').length > 0)
			{
				if(e.keyCode == 27)
					closeModalwindows();
			}
		});
}

/* ######################################################################################################################## */
/* FUNCTION (TOGGLE MODAL WINDOW) ######################################################################################### */
/* ######################################################################################################################## */
function toggleModalwindow(idModal)
{
	var speed = '';
		ease  = '';

	jQuery(idModal).stop(true, true).fadeToggle(speed, ease, function()
	{
		if(jQuery(idModal).hasClass('open'))
		{
			jQuery(idModal).removeClass('open');
			document.body.style.overflow = "";
		}
		else
		{
			jQuery(idModal).addClass('open');
			document.body.style.overflow = "hidden";
		}

		checkModalHeight(idModal);
	});

	jQuery(window).bind('resizeEnd', function(){ checkModalHeight(idModal) });
}

/* ######################################################################################################################## */
/* FUNCTION (CLOSE MODAL WINDOW) ########################################################################################## */
/* ######################################################################################################################## */
function closeModalwindows()
{
	jQuery('.js-modal_wrapper').removeClass('open');
	document.body.style.overflow = "";

	jQuery('.js-modal_wrapper').stop(true, true).fadeOut(speed, ease);
}

/* ######################################################################################################################## */
/* FUNCTION (CHECK MODAL HEIGHT) ########################################################################################## */
/* ######################################################################################################################## */
function checkModalHeight(idModal)
{
	var thisModal     = jQuery(idModal);
		modalContent  = jQuery('.js-modal_content', idModal);
		hModalContent = modalContent.outerHeight(true);
		hBody         = jQuery(window).height();

	if(hModalContent >= hBody)
		thisModal.addClass('fixedHeight');
	else
		thisModal.removeClass('fixedHeight');
}
