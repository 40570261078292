/* ######################################################################################################################## */
/* GENERIC VARS ########################################################################################################### */
/* ######################################################################################################################## */
var speed = 300;
var	ease  = 'easeInOutQuart';

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW ONLOAD) ################################################################################################ */
/* ######################################################################################################################## */
window.addEventListener('load', function ()
{
	var scrollPX = 98;

	if(jQuery(window).width() <= 768)
		scrollPX = 72;

	if(document.location.hash != '')
	{
		if (!jQuery(document.location.hash).length) {
			return false;
		}

		jQuery('html, body').animate(
		{
			scrollTop : jQuery(document.location.hash).offset().top - scrollPX
		}, speed * 2, ease, function()
		{
			mainNavigationActiveState();
		});
	}

	$(window).trigger('resize');
});


// New mobile header
// Elements
const navTriggerBtnEle = document.getElementById('js-main-navigation-trigger');
const mainNavigationEle = document.getElementById('main-navigation');
const headerEle = document.getElementById('header');
const navUlEle = document.getElementById('main_nav_ul');

// Toggle funct
function clearShowLi() {
	for(var i = 0; i < navUlEle.children.length; i++) {
		navUlEle.children[i].classList.remove('show');
	}
}
function toggleMobileNav() {

	// if closed
	if(!navTriggerBtnEle.classList.contains('open')) {
		clearShowLi();
		document.body.style.overflow = "hidden";

		// open 
		navTriggerBtnEle.classList.add('open');
		mainNavigationEle.classList.add('open');
		headerEle.classList.add('open');

		// Add show li
		var i = 0;
		function loopLi() {    
			setTimeout(function() {  
				navUlEle.children[i].classList.add('show');   
				i++;                   
				if(i < navUlEle.children.length) {          
					loopLi();             
				}                    
			}, 100); 
		}
		loopLi();   

		setTimeout(function() {
			navTriggerBtnEle.classList.add('merge');
		}, 100);
		setTimeout(function() {
			navTriggerBtnEle.classList.add('rotate');
		}, 200);
	}
	else {
		// close
		document.body.style.overflow = "";
		navTriggerBtnEle.classList.remove('open');
		mainNavigationEle.classList.remove('open');
		navTriggerBtnEle.classList.remove('merge');
		navTriggerBtnEle.classList.remove('rotate');
		headerEle.classList.remove('open');
		clearShowLi();
	}
} 

// Toggle event listener
navTriggerBtnEle.addEventListener('click', toggleMobileNav, true);


/* ######################################################################################################################## */
/* SCRIPTS (DOCUMENT READY) ############################################################################################### */
/* ######################################################################################################################## */
// jQuery(document).ready(function($)
// {
	cookieNotice();                 // Cookie notice
	stickyHeader();                 // Sticky header
	mainNavigationActiveState();    // Main navigation active state
	latestNewsCarousel();           // Latest news carousel
	latestCaseStudiesCarousel();    // Latest case studies carousel
	modalWindow(speed, ease);       // Modal window

	// Hero
	heroRatio();

	// Cycle
	jQuery('.cycle').each(function(index, element)
	{
		var classSlide = jQuery(element).attr('data-cycle-slides').substr(4);

		if(jQuery(classSlide, element).length > 1)
		{
			jQuery(element).cycle(
			{
				easing : ease,
				log    : false
			});
		}
	});

	// Goto ID
	jQuery('body').delegate('.goToID', 'click', function(e)
	{
		e.preventDefault();

		var idDiv     = jQuery(this).attr('href');

		if (!jQuery(idDiv).length) {
			return false;
		}

		var offsetTop = jQuery(idDiv).offset().top;
		var delay     = 0;

		if(jQuery('#header').hasClass('is-sticky'))
			var scrollPX = jQuery('#header').outerHeight(true) - 2;
		else
			var scrollPX = 98;

		if(jQuery(window).width() <= 768)
			scrollPX = 72;

		// Close nav
		setTimeout(function() {
			if(navTriggerBtnEle.classList.contains('open')) { 
		// close
		document.body.style.overflow = "";
		navTriggerBtnEle.classList.remove('open');
		mainNavigationEle.classList.remove('open');
		navTriggerBtnEle.classList.remove('merge');
		navTriggerBtnEle.classList.remove('rotate');
		headerEle.classList.remove('open');
		clearShowLi();
			}
			delay = speed;
		}, speed)

		jQuery('html, body').stop(true, true).delay(delay).animate(
		{
			scrollTop : offsetTop - scrollPX
		}, speed * 2, ease, function()
		{
			mainNavigationActiveState();
			history.pushState(null, null, idDiv);
		});
	});

	// Change select value
	/*
	jQuery('body').delegate('.js-change_select_value', 'click', function(e)
	{
		e.preventDefault();

		var selectID    = jQuery(this).attr('data-select-id');
		var selectValue = jQuery(this).attr('data-select-value');

		jQuery(selectID).val(selectValue).change();
	});
	*/

	// Main navigation
	// jQuery('#js-main-navigation-trigger').on('click', function()
	// {
	// 	trigger = jQuery(this);
	// 	nav     = jQuery('#main-navigation');
	// 	ul      = jQuery('ul', '#main-navigation');

	// 	trigger.toggleClass('open');
	// 	nav.toggleClass('open');

	// 	if(nav.hasClass('open'))
	// 		document.body.style.overflow = "hidden";
	// 	else
	// 		document.body.style.overflow = "";
	// });

	// Clients
	var clients = [];

	jQuery('.item.hide', '.list_type_1').each(function()
	{
		clients.push(jQuery(this));
	});

	var timer = setInterval(function()
	{
		var randomItem    = Math.floor(Math.random() * 12);
		var randomClient  = Math.floor(Math.random() * clients.length);
		var nextClient    = clients[randomClient];

		jQuery('.list_type_1 .item').eq(randomItem).find('.lazyload_wrapper').animate(
		{
			'opacity' : 0
		}, speed * 2, ease, function()
		{
			var currentItem  = jQuery(this).parent();
			var currentImg   = currentItem.find('img:not([data-object-fit-polyfilled])');
			var nextImg      = nextClient.find('img:not([data-object-fit-polyfilled])');
			var nextClientID = nextClient.attr('id');

			currentImg.after(nextImg);
			currentImg.remove();

			jQuery('#' + nextClientID).find('.lazyload_wrapper').html(currentImg);
		}).animate({ 'opacity' : 1 }, speed * 4, ease);
	}, speed * 8);

	// Prevent default anchor action
	jQuery('body').delegate('a', 'click', function(e)
	{
		if(jQuery(this).attr('href') == '#')
			e.preventDefault();
	});

	jQuery('#logos').slick(
	{
		arrows        : true,
		centerMode    : true,
		centerPadding : 0,
		infinite      : true,
		variableWidth : true,
		slidesToShow  : 1,
		initialSlide  : 1
	});

	// Same height
	same_height();
// });

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW RESIZE) ################################################################################################ */
/* ######################################################################################################################## */
jQuery(window).on('resizeEnd', function()
{
	// Hero
	heroRatio();

	// Latest news carousel
	latestNewsCarousel(); 
	latestCaseStudiesCarousel(); 

	// Update Header top pos to stay under alet banner if active
	var alerBannerHeaderEle = document.querySelector('.alert-banner-visible');
	var alertBannerEle = document.querySelector('.alert_banner_con');
	if(alerBannerHeaderEle) {
		alerBannerHeaderEle.style.top = alertBannerEle.offsetHeight + 'px';
		document.body.style.marginTop = alertBannerEle.offsetHeight + 'px';
	}
 
	// Main navigation
	if((jQuery(document).width() > 768) && (jQuery('.main_navigation').hasClass('open')))
	{
		navTriggerBtnEle.click();

		// Return to here
		// jQuery('#js-main-navigation-trigger').removeClass('open');
		// jQuery('#main-navigation').removeClass('open');
	}

	// Same height
	same_height();
});

// resizeEnd
jQuery(window).on('resize', function()
{
	if(this.resizeTO)
		clearTimeout(this.resizeTO);

	this.resizeTO = setTimeout(function()
	{
		jQuery(this).trigger('resizeEnd');
	}, 10);
});

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW SCROLL) ################################################################################################ */
/* ######################################################################################################################## */
jQuery(window).on('scroll', function()
{
	// Main navigation active state
	mainNavigationActiveState();
});

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW MOUSEMOVE) ############################################################################################# */
/* ######################################################################################################################## */

/*
jQuery(window).mousemove(function(e)
{
	var wWindow = window.screen.width;
	var xPos    = e.clientX;
	var move    = xPos - (wWindow / 2);
	var opacity = Math.abs(parseInt((move * 100) / (wWindow / 2)));

	// Hero
	jQuery('.background.slice_01 img').css('object-position', ((0 + (move / 80)) + "px"));
	jQuery('.background.slice_02 img').css('object-position', (- (0 + (move / 80)) + "px"));
	jQuery('.background.slice_03 img').css('object-position', (- (0 + (move / 80)) + "px"));
	jQuery('.background.slice_04 img').css('object-position', ((0 + (move / 80)) + "px"));

	jQuery('.background.slice_02, .background.slice_04').css('opacity', 1 - ((opacity * 48) / 10000));
	jQuery('.background.slice_03').css('opacity', 1 - ((opacity * 32) / 10000));

	// Hero-in
	jQuery('.background.slice_05 img').css('object-position', ((0 + (move / 80)) + "px"));
	jQuery('.background.slice_06 img').css('object-position', ((0 + (move / 80)) + "px"));
	jQuery('.background.slice_07 img').css('object-position', (- (0 + (move / 80)) + "px"));

	jQuery('.background.slice_05, .background.slice_06').css('opacity', 1 - ((opacity * 48) / 10000));
	*/

	/*
	// Hero
	jQuery('.svgImage01 image').css('transform', 'translateX(' + ((0 + (move / 80)) + "px") + ')');
	jQuery('.svgImage02 image').css('transform', 'translateX(' + (- (0 + (move / 80)) + "px") + ')');
	jQuery('.svgImage03 image').css('transform', 'translateX(' + (- (0 + (move / 80)) + "px") + ')');
	jQuery('.svgImage04 image').css('transform', 'translateX(' + ((0 + (move / 80)) + "px") + ')');

	jQuery('.svgImage02 image, .svgImage04 image').css('opacity', 1 - ((opacity * 48) / 10000));
	jQuery('.svgImage03 image').css('opacity', 1 - ((opacity * 32) / 10000));

	// Hero-in
	jQuery('.svgImage05 image').css('transform', 'translateX(' + ((0 + (move / 80)) + "px") + ')');
	jQuery('.svgImage06 image').css('transform', 'translateX(' + ((0 + (move / 80)) + "px") + ')');
	jQuery('.svgImage07 image').css('transform', 'translateX(' + (- (0 + (move / 80)) + "px") + ')');

	jQuery('.svgImage05 image, .svgImage06 image').css('opacity', 1 - ((opacity * 48) / 10000));
});
*/

// Hero
$('.hero_bg').each(function()
{
	var heroBgID = $(this).attr('id');
	var bgImage  = new Image();
	var canvas   = document.getElementById(heroBgID);

	bgImage.src = $('#' + heroBgID).attr('data-bgimage');

	bgImage.onload = function()
	{
		jQuery(window).on('mousemove', function(e)
		{
			var wWindow  = window.screen.width;
			var xPos     = e.clientX;
			var move     = xPos - (wWindow / 2);
			var opacity  = Math.abs(parseInt((move * 100) / (wWindow / 2)));
			var ctx      = canvas.getContext('2d');

			$('#' + heroBgID).removeClass('hidden');

			// Segment #1
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(0, 0);
			ctx.lineTo(0, 140.4);
			ctx.lineTo(1920, 766.8);
			ctx.lineTo(1920, 324);
			ctx.lineTo(710.4, 0);
			ctx.clip();
			ctx.drawImage(bgImage, (0 + (move / 80)), 0, 1920, 1080);
			ctx.closePath();
			ctx.restore();

			// Segment #2
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(710.4, 0);
			ctx.lineTo(1920, 324);
			ctx.lineTo(1920, 0);
			ctx.fillStyle = "black";
			ctx.fill();
			ctx.clip();
			ctx.globalAlpha = (1 - ((opacity * 48) / 10000));
			ctx.drawImage(bgImage, (- (0 + (move / 80))), 0, 1920, 1080);
			ctx.closePath();
			ctx.restore();

			// Segment #3
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(0, 702);
			ctx.lineTo(1382.4, 1080);
			ctx.lineTo(1920, 1080);
			ctx.lineTo(1920, 766.8);
			ctx.lineTo(0, 140.4);
			ctx.fillStyle = "black";
			ctx.fill();
			ctx.clip();
			ctx.globalAlpha = (1 - ((opacity * 32) / 10000));
			ctx.drawImage(bgImage, (- (0 + (move / 80))), 0, 1920, 1080);
			ctx.closePath();
			ctx.restore();

			// Segment #4
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(0, 1080);
			ctx.lineTo(1382.4, 1080);
			ctx.lineTo(0, 702);
			ctx.fillStyle = "black";
			ctx.fill();
			ctx.clip();
			ctx.globalAlpha = (1 - ((opacity * 48) / 10000));
			ctx.drawImage(bgImage, (0 + (move / 80)), 0, 1920, 1080);
			ctx.closePath();
			ctx.restore();
		});
	}
});

// Hero in
$('.hero_in').each(function()
{
	var heroBgID = $('.hero_in_bg', this).attr('id');
	var bgImage  = new Image();
	var canvas   = document.getElementById(heroBgID);

	bgImage.src = $('#' + heroBgID).attr('data-bgimage');

	bgImage.onload = function()
	{
		jQuery(window).on('mousemove', function(e)
		{
			var wWindow  = window.screen.width;
			var xPos     = e.clientX;
			var move     = xPos - (wWindow / 2);
			var opacity  = Math.abs(parseInt((move * 100) / (wWindow / 2)));
			var ctx      = canvas.getContext('2d');

			$('#' + heroBgID).removeClass('hidden');

			// Segment #1
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(1920, 0);
			ctx.lineTo(537.6, 0);
			ctx.lineTo(1920, 453.6);
			ctx.fillStyle = "black";
			ctx.fill();
			ctx.clip();
			ctx.globalAlpha = (1 - ((opacity * 48) / 10000));
			ctx.drawImage(bgImage, ((0 + (move / 80))), 0, 1920, 720);
			ctx.closePath();
			ctx.restore();

			// Segment #2
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(0, 720);
			ctx.lineTo(1363.2, 720);
			ctx.lineTo(0, 259.2);
			ctx.fillStyle = "black";
			ctx.fill();
			ctx.clip();
			ctx.globalAlpha = (1 - ((opacity * 48) / 10000));
			ctx.drawImage(bgImage, ((0 + (move / 80))), 0, 1920, 720);
			ctx.closePath();
			ctx.restore();

			// Segment #3
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(1920, 453.6);
			ctx.lineTo(537.6, 0);
			ctx.lineTo(0, 0);
			ctx.lineTo(0, 259.2);
			ctx.lineTo(1363.2, 720);
			ctx.lineTo(1920, 720);
			ctx.clip();
			ctx.drawImage(bgImage, (- (0 + (move / 80))), 0, 1920, 720);
			ctx.closePath();
			ctx.restore();
		});
	}
});

/* ######################################################################################################################## */
/* FUNCTION (MAIN NAVIGATION) ############################################################################################# */
/* ######################################################################################################################## */
function mainNavigationActiveState()
{
	jQuery('.main_navigation > .main_navigation-inner > .navLinksUl > li').each(function()
	{
		var section = jQuery('.goToID', this).attr('href');

		if(jQuery(section).length)
		{
			var offset = jQuery(section).offset().top - jQuery('#header').outerHeight();

			if(jQuery(window).scrollTop() >= (offset - 1))
			{
				jQuery('.main_navigation > .main_navigation-inner > .navLinksUl > li').removeClass('active');
				jQuery(this).addClass('active');
			}
			else
				jQuery(this).removeClass('active');
		}
	});
}

/* ######################################################################################################################## */
/* FUNCTION (LATEST NEWS CAROUSEL) ######################################################################################## */
/* ######################################################################################################################## */
function latestNewsCarousel()
{
	if(jQuery(document).width() <= 768)
	{
		if(jQuery('#cycle-latest-news').attr('data-cycle-carousel-visible') != 1)
		{
			jQuery('#cycle-latest-news').attr('data-cycle-carousel-visible', '1');
			jQuery('#cycle-latest-news').cycle('reinit');
		}

		if(jQuery('#cycle-latest-news').attr('data-num-items') > 1)
			jQuery('#cycle-latest-news').next('.controls').removeClass('hide');
		else
			jQuery('#cycle-latest-news').next('.controls').addClass('hide');
	}
	else
	{
		if(jQuery('#cycle-latest-news').attr('data-cycle-carousel-visible') != 2)
		{
			jQuery('#cycle-latest-news').attr('data-cycle-carousel-visible', '2');
			jQuery('#cycle-latest-news').cycle('reinit');
		}

		if(jQuery('#cycle-latest-news').attr('data-num-items') > 2)
			jQuery('#cycle-latest-news').next('.controls').removeClass('hide');
		else
			jQuery('#cycle-latest-news').next('.controls').addClass('hide');
	}
}

/* ######################################################################################################################## */
/* FUNCTION (LATEST CASE STUDIES CAROUSEL) ################################################################################ */
/* ######################################################################################################################## */
function latestCaseStudiesCarousel()
{
	if(jQuery(document).width() >= 1024)
	{
		if(jQuery('#case-studies-cycle').attr('data-cycle-carousel-visible') != 3)
		{
			jQuery('#case-studies-cycle').attr('data-cycle-carousel-visible', '3');
			jQuery('#case-studies-cycle').cycle('reinit');
		}
	}
	else if(jQuery(document).width() >= 768)
	{
		if(jQuery('#case-studies-cycle').attr('data-cycle-carousel-visible') != 2)
		{
			jQuery('#case-studies-cycle').attr('data-cycle-carousel-visible', '2');
			jQuery('#case-studies-cycle').cycle('reinit');
		}
	}
	else
	{
		if(jQuery('#case-studies-cycle').attr('data-cycle-carousel-visible') != 1)
		{
			jQuery('#case-studies-cycle').attr('data-cycle-carousel-visible', '1');
			jQuery('#case-studies-cycle').cycle('reinit');
		}
	}
}

/* ######################################################################################################################## */
/* FORM BEHAVIOUR ######################################################################################################### */
/* ######################################################################################################################## */
var enquirySource = document.getElementById('enquiry_source');
var enquirySourceText = document.getElementById('enquiry_source_text_wrapper');

if (enquirySource && enquirySourceText) {
	enquirySource.addEventListener('change', function(e) {
		if (e.target.value == 'Other') {
			enquirySourceText.style.maxHeight = enquirySourceText.scrollHeight + 'px';
		} else {
			enquirySourceText.style.maxHeight = null;
		}
	});

	window.addEventListener('resize', function() {
		if (enquirySource.value == 'Other') {
			enquirySourceText.style.maxHeight = enquirySourceText.scrollHeight + 'px';
		} else {
			enquirySourceText.style.maxHeight = null;
		}
	});
}


/* ######################################################################################################################## */
/* FORM VALIDATION ######################################################################################################## */
/* ######################################################################################################################## */
$.fn.validate = function() {
	return $(this).each(function() {
		var $field     = $(this).closest('.js-field'),
		$fieldFeedback = $field.find('.js-field-feedback'),
		validity       = $(this).prop('validity');

		$field.removeClass('is-valid is-invalid');

		if (validity.valid === true) {
			$field.addClass('is-valid');
		} else if (validity.valid === false) {
			var message = 'There is a problem with the data inputted';
			if (!$fieldFeedback.data('validation-msg')) {
				if (validity.valueMissing === true) {
					message = 'This is a required field';
				} else if (validity.typeMismatch === true && $(this).attr('type') === 'email') {
					message = 'Please enter a valid email address';
				} else if (validity.typeMismatch === true && $(this).attr('type') === 'url') {
					message = 'Please enter a valid website address';
				} else if (validity.tooShort === true) {
					message = 'Must be a minimum of ' + $(this).attr('minlength') + ' characters';
				} else if (validity.tooLong === true) {
					message = 'Must be a maximum of ' + $(this).attr('maxLength') + ' characters';
				}
			} else {
				message = $fieldFeedback.data('validation-msg');
			}

			$fieldFeedback.html(message);
			$field.addClass('is-invalid');
		}
	});
};

$('.js-field-control').on('change', function() {
	$(this).validate();
});

$('.js-validate').attr('novalidate', 'novalidate').submit(function(e) {
	e.preventDefault();
	$(this).find('input, select, textarea').validate();

	if ($(this).find('.js-field.is-invalid').length) {

		$(this).find('.js-alert.alert-invalid').addClass('is-active');

		$('.is-invalid', $(this)).first().find('input, select, textarea').focus();
	} else {
		$(this).find('.js-alert.alert-invalid').removeClass('is-active');
		$(this).find('.js-btn-ajax').addClass('is-loading');

		var $recaptcha = $(this).find('.g-recaptcha');

		if ($recaptcha.length) {
			var recaptchaId = $recaptcha.data('recaptcha-id');

			currentRecaptchaId = recaptchaId;
			grecaptcha.execute(recaptchaId);
		} else {
			this.submit();
		}
	}
});

/* ######################################################################################################################## */
/* FUNCTION (HERO RATIO) ################################################################################################## */
/* ######################################################################################################################## */
function heroRatio()
{
	// Hero
	var heroW = $('#hero').width();
	var heroH = $('#hero').height();
	var heroR = heroW / heroH;

	if(heroR < (1920 / 1080))
		$('.hero_bg').addClass('portrait');
	else
		$('.hero_bg').removeClass('portrait');

	// Hero in
	$('.hero_in').each(function()
	{
		var heroW = $(this).width();
		var heroH = $(this).height();
		var heroR = heroW / heroH;

		if(heroR < (1920 / 720))
			$('.hero_bg', this).addClass('portrait');
		else
			$('.hero_bg', this).removeClass('portrait');
	});
}

/* ################################################################### */
/* FUNCTION (SAME HEIGHT) ############################################ */
/* ################################################################### */
function same_height(elem)
{
	document.body.style.overflow = "hidden";

	if(jQuery(window).width() >= 768)
	{
		jQuery('.sh_container').each(function()
		{
			var h_elem = 0;

			jQuery('.sh_element', jQuery(this))
				.css({ 'height' : '' })
				.each(function()
				{
					if(h_elem < jQuery(this).height())
						h_elem = jQuery(this).height();
				})
				.height(h_elem);
		});
	}
	else
		jQuery('.sh_element', '.sh_container').css('height', '');

	document.body.style.overflow = "";
}



